import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listLaboratory = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LABORATORY_LIST, payload);
    return data
}

export const searchLaboratory = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LABORATORY_SEARCH, payload);
    return data
}

export const storeLaboratory = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LABORATORY_STORE, payload);
    return data
}

export const showLaboratory = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.LABORATORY_SHOW + `/${id}`);
    return data
}

export const editLaboratory = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.LABORATORY_EDIT + `/${id}`, payload);
    return data
}

export const deleteLaboratory = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.LABORATORY_DELETE + `/${id}`);
    return data
}