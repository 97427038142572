import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listClassification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CLASSIFICATION_LIST, payload);
    return data
}

export const searchClassification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CLASSIFICATION_SEARCH, payload);
    return data
}

export const storeClassification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CLASSIFICATION_STORE, payload);
    return data
}

export const showClassification = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.CLASSIFICATION_SHOW + `/${id}`);
    return data
}

export const editClassification = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.CLASSIFICATION_EDIT + `/${id}`, payload);
    return data
}

export const deleteClassification = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.CLASSIFICATION_DELETE + `/${id}`);
    return data
}