import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listPathology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.PATHOLOGY_LIST, payload);
    return data
}

export const searchPathology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.PATHOLOGY_SEARCH, payload);
    return data
}

export const storePathology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.PATHOLOGY_STORE, payload);
    return data
}

export const showPathology = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.PATHOLOGY_SHOW + `/${id}`);
    return data
}

export const editPathology = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.PATHOLOGY_EDIT + `/${id}`, payload);
    return data
}

export const deletePathology = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.PATHOLOGY_DELETE + `/${id}`);
    return data
}