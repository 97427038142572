import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listSpecialty = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.SPECIALTY_LIST, payload);
    return data
}

export const searchSpecialty = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.SPECIALTY_SEARCH, payload);
    return data
}

export const storeSpecialty = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.SPECIALTY_STORE, payload);
    return data
}

export const showSpecialty = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.SPECIALTY_SHOW + `/${id}`);
    return data
}

export const editSpecialty = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.SPECIALTY_EDIT + `/${id}`, payload);
    return data
}

export const deleteSpecialty = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.SPECIALTY_DELETE + `/${id}`);
    return data
}