import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listAnatomyPhysiology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.ANATOMY_PHYSIOLOGY_LIST, payload);
    return data
}

export const searchAnatomyPhysiology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.ANATOMY_PHYSIOLOGY_SEARCH, payload);
    return data
}

export const storeAnatomyPhysiology = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.ANATOMY_PHYSIOLOGY_STORE, payload);
    return data
}

export const showAnatomyPhysiology = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.ANATOMY_PHYSIOLOGY_SHOW + `/${id}`);
    return data
}

export const editAnatomyPhysiology = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.ANATOMY_PHYSIOLOGY_EDIT + `/${id}`, payload);
    return data
}

export const deleteAnatomyPhysiology = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.ANATOMY_PHYSIOLOGY_DELETE + `/${id}`);
    return data
}