<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="6">
          <v-autocomplete
            v-model="formData.type"
            :items="['FQM', 'Concorrente']"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Tipo"
            label="Tipo"
            outlined
            dense
            :clearable="true"
            :required="formData.status === 'Publicado'"
            :rules="formData.status === 'Publicado' ? rules.selectRules : []"
            :disabled="viewOnly"
          ></v-autocomplete>

          <v-select
            v-model="formData.status"
            :items="['Rascunho', 'Publicado']"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Status"
            label="Status"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
            :disabled="viewOnly"
          ></v-select>

          <div>
            <div v-if="viewOnly">
              <div v-if="logoPreview">
                <span class="caption">Logo produto</span>
                <v-img
                  :src="logoPreview"
                  max-width="150"
                  max-height="150"
                  contain
                  class="rounded-lg mb-6"
                >
                </v-img>
              </div>
            </div>

            <div class="d-flex justify-space-between align-start" v-else>
              <div>
                <v-btn
                  v-if="logoPreview && !formData.brandImageFile"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="red"
                  @click="logoPreview = null"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
                <v-avatar color="primary" size="40" class="mr-4">
                  <v-icon dark v-if="!logoPreview"> mdi-image </v-icon>
                  <img v-else :src="logoPreview" contain />
                </v-avatar>
              </div>
              <v-file-input
                accept="image/png, image/jpeg"
                placeholder="Logo produto"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                label="Logo produto"
                outlined
                :chips="true"
                small-chips
                dense
                @change="preview"
                v-model="formData.brandImageFile"
                :disabled="viewOnly"
                @click:clear="clearPreview"
              ></v-file-input>
            </div>
          </div>

          <div>
            <div v-if="viewOnly">
              <div v-if="produtoPreview">
                <span class="caption">Imagem produto</span>
                <v-img
                  :src="produtoPreview"
                  max-width="150"
                  max-height="150"
                  contain
                  class="rounded-lg mb-6"
                >
                </v-img>
              </div>
            </div>
            <div class="d-flex justify-space-between align-start" v-else>
              <div>
                <v-btn
                  v-if="produtoPreview && !formData.productImageFile"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="red"
                  @click="produtoPreview = null"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
              </div>
              <v-avatar color="primary" size="40" class="mr-4">
                <v-icon dark v-if="!produtoPreview"> mdi-image </v-icon>
                <img v-else :src="produtoPreview" contain />
              </v-avatar>
              <v-file-input
                accept="image/png, image/jpeg"
                placeholder="Imagem produto"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                label="Imagem produto"
                outlined
                :chips="true"
                small-chips
                dense
                @change="previewProduto"
                v-model="formData.productImageFile"
                :disabled="viewOnly"
                @click:clear="clearPreviewProduto"
              ></v-file-input>
            </div>
          </div>

          <v-text-field
            v-model="formData.name"
            placeholder="Marca (nome)"
            outlined
            dense
            :clearable="true"
            label="Marca (nome)"
            required
            :rules="rules.genericRules"
            :disabled="viewOnly"
          >
          </v-text-field>

          <v-combobox
            v-model="formData.linesId"
            :items="listLines"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Linha"
            label="Linha"
            outlined
            dense
            small-chips
            deletable-chips
            :clearable="true"
            :required="formData.type === 'FQM'"
            :rules="formData.type === 'FQM' ? rules.selectRules : []"
            return-object
            :disabled="viewOnly"
          ></v-combobox>

          <v-autocomplete
            v-model="formData.laboratoriesId"
            :items="listLaboratories"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Laboratório"
            label="Laboratório"
            outlined
            dense
            :clearable="true"
            :required="formData.status === 'Publicado'"
            :rules="formData.status === 'Publicado' ? rules.selectRules : []"
            :disabled="viewOnly"
          ></v-autocomplete>

          <v-combobox
            v-model="formData.salesForcesIds"
            :items="listSalesFroces"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Força de Vendas"
            label="Força de Vendas"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            :clearable="true"
            return-object
            :required="formData.status === 'Publicado'"
            :rules="
              formData.status === 'Publicado' ? rules.multiSelectRules : []
            "
            :disabled="viewOnly"
          ></v-combobox>

          <v-editor
            @update="formData.composition = $event"
            label="Composição"
            :value="formData.composition"
            :required="formData.status === 'Publicado'"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-text-field
            v-model="formData.therapeuticClass"
            placeholder="Classe terapêutica"
            outlined
            dense
            label="Classe terapêutica"
            :disabled="viewOnly"
          ></v-text-field>

          <v-combobox
            v-model="formData.classificationsId"
            :items="listClassifications"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Classificação"
            label="Classificação"
            outlined
            dense
            small-chips
            deletable-chips
            return-object
            :disabled="viewOnly"
          ></v-combobox>

          <v-combobox
            v-model="formData.anatomiesPhysiologiesId"
            :items="listAnatomiesPhysiologies"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Anatomia e Fisiologia"
            label="Anatomia e Fisiologia"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            :clearable="true"
            return-object
            :disabled="viewOnly"
          ></v-combobox>

          <v-combobox
            v-model="formData.pathologiesId"
            :items="listPathologies"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Patologia"
            label="Patologia"
            outlined
            multiple
            dense
            small-chips
            deletable-chips
            :clearable="true"
            return-object
            :disabled="viewOnly"
          ></v-combobox>

          <v-editor
            @update="formData.mechanismAction = $event"
            label="Mecanismo de ação"
            :value="formData.mechanismAction"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.presentations = $event"
            label="Apresentações"
            :value="formData.presentations"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.indication = $event"
            label="Indicação"
            :value="formData.indication"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.dosage = $event"
            label="Posologia"
            :value="formData.dosage"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.patientProfile = $event"
            label="Perfil de paciente"
            :value="formData.patientProfile"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.benefits = $event"
            label="Benefícios"
            :value="formData.benefits"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.advantages = $event"
            label="Vantagens"
            :value="formData.advantages"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.characteristics = $event"
            label="Características"
            :value="formData.characteristics"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.strengths = $event"
            label="Pontos Fortes"
            :value="formData.strengths"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.weaknesses = $event"
            label="Pontos Fracos"
            :value="formData.weaknesses"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.keyMessage = $event"
            label="Mensagem-chave sugerida"
            :value="formData.keyMessage"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.suggestedApproach = $event"
            label="Abordagem sugerida"
            :value="formData.suggestedApproach"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.pdv = $event"
            label="PDV"
            :value="formData.pdv"
            :disabled="viewOnly"
          >
          </v-editor>

          <div class="links">
            <p class="caption mb-1">Links</p>
            <v-form
              ref="formLinks"
              v-model="validLinks"
              lazy-validation
              class="d-flex justify-space-between"
            >
              <v-text-field
                v-model="title"
                placeholder="Titulo"
                outlined
                dense
                :required="formData.status === 'Publicado'"
                :rules="rules.genericRules"
                :disabled="viewOnly"
              ></v-text-field>

              <v-text-field
                class="mx-4"
                v-model="url"
                placeholder="Link"
                outlined
                dense
                :required="formData.status === 'Publicado'"
                :rules="rules.urlRules"
                :disabled="viewOnly"
              ></v-text-field>

              <v-btn
                depressed
                fab
                dark
                small
                color="fqm_gray"
                @click="adicionarLink"
                :disabled="viewOnly"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-form>
            <v-simple-table class="mb-6" v-if="formData.links">
              <template v-slot:default>
                <thead class="grey lighten-4">
                  <tr>
                    <th class="text-left">Titulo</th>
                    <th class="text-left">URL</th>
                    <th v-if="!viewOnly" class="text-left">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in formData.links" :key="item.index">
                    <td>{{ item.title }}</td>
                    <td>
                      <a :href="item.url" target="_blank">{{ item.url }}</a>
                    </td>
                    <td v-if="!viewOnly">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removerLink(index)"
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div>
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="directProductsId"
                :items="listProducts"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Concorrentes Diretos"
                label="Concorrentes Diretos"
                outlined
                dense
                return-object
                clearable
                :disabled="viewOnly"
              ></v-autocomplete>
              <v-btn
                class="ml-4"
                depressed
                fab
                dark
                small
                color="fqm_gray"
                @click="
                  adicionarProduto(formData.directProductsId, directProductsId);
                  directProductsId = null;
                "
                :disabled="viewOnly"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <v-simple-table
              class="mb-6"
              v-if="formData.directProductsId.length > 0"
            >
              <template v-slot:default>
                <thead class="grey lighten-4">
                  <tr>
                    <th class="text-left">Marca</th>
                    <th class="text-left">Laboratório</th>
                    <th v-if="!viewOnly" class="text-left">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in formData.directProductsId"
                    :key="item.id"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.laboratory ? item.laboratory.name : null }}
                    </td>
                    <td v-if="!viewOnly">
                      <v-btn
                        class="mr-2"
                        depressed
                        fab
                        dark
                        x-small
                        color="blue"
                        @click="goTo(item.id)"
                      >
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        @click="
                          removerProduto(formData.directProductsId, index)
                        "
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div>
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="indirectProductsId"
                :items="listProducts"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Concorrentes Indiretos"
                label="Concorrentes Indiretos"
                outlined
                dense
                return-object
                clearable
                :disabled="viewOnly"
              ></v-autocomplete>
              <v-btn
                class="ml-4"
                depressed
                fab
                dark
                small
                color="fqm_gray"
                @click="
                  adicionarProduto(
                    formData.indirectProductsId,
                    indirectProductsId
                  );
                  indirectProductsId = null;
                "
                :disabled="viewOnly"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <v-simple-table
              class="mb-6"
              v-if="formData.indirectProductsId.length > 0"
            >
              <template v-slot:default>
                <thead class="grey lighten-4">
                  <tr>
                    <th class="text-left">Marca</th>
                    <th class="text-left">Laboratório</th>
                    <th v-if="!viewOnly" class="text-left">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in formData.indirectProductsId"
                    :key="item.label"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.laboratory ? item.laboratory.name : null }}
                    </td>
                    <td v-if="!viewOnly">
                      <v-btn
                        class="mr-2"
                        depressed
                        fab
                        dark
                        x-small
                        color="blue"
                        @click="goTo(item.id)"
                      >
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        @click="
                          removerProduto(formData.indirectProductsId, index)
                        "
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <v-combobox
            v-model="formData.specialtiesId"
            :items="listSpecialties"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Especialidades"
            label="Especialidades"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            :clearable="true"
            return-object
            :required="formData.status === 'Publicado'"
            :rules="
              formData.status === 'Publicado' ? rules.multiSelectRules : []
            "
            :disabled="viewOnly"
          ></v-combobox>

          <v-editor
            @update="formData.observation1 = $event"
            label="Observações 1:"
            :value="formData.observation1"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.observation2 = $event"
            label="Observações 2:"
            :value="formData.observation2"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.observation3 = $event"
            label="Observações 3:"
            :value="formData.observation3"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.observation4 = $event"
            label="Observações 4:"
            :value="formData.observation4"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-editor
            @update="formData.observation5 = $event"
            label="Observações 5:"
            :value="formData.observation5"
            :disabled="viewOnly"
          >
          </v-editor>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        v-if="!viewOnly"
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "ProdutoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    listLines: {
      type: Array,
      default: () => [],
    },
    listLaboratories: {
      type: Array,
      default: () => [],
    },
    listClassifications: {
      type: Array,
      default: () => [],
    },
    listAnatomiesPhysiologies: {
      type: Array,
      default: () => [],
    },
    listPathologies: {
      type: Array,
      default: () => [],
    },
    listProducts: {
      type: Array,
      default: () => [],
    },
    listSpecialties: {
      type: Array,
      default: () => [],
    },
    listSalesFroces: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      type: null,
      status: "Rascunho",
      brandImageFile: null,
      productImageFile: null,
      name: null,
      linesId: null,
      laboratoriesId: null,
      salesForcesIds: [],
      composition: null,
      therapeuticClass: "",
      classificationsId: null,
      anatomiesPhysiologiesId: [],
      pathologiesId: [],
      mechanismAction: "",
      presentations: "",
      indication: "",
      dosage: "",
      patientProfile: "",
      benefits: "",
      advantages: "",
      strengths: "",
      weaknesses: "",
      characteristics: "",
      keyMessage: "",
      directProductsId: [],
      indirectProductsId: [],
      suggestedApproach: "",
      pdv: "",
      links: [],
      observation1: "",
      observation2: "",
      observation3: "",
      observation4: "",
      observation5: "",
    },
    logoPreview: null,
    produtoPreview: null,
    directProductsId: null,
    indirectProductsId: null,
    title: null,
    url: null,
    rules: rules,
    valid: true,
    isEdit: false,
    viewOnly: false,
    validLinks: true,
    required: false,
  }),
  created() {
    if (this.$route.name === "ProdutoVisualizar") {
      this.viewOnly = true;
    }
    if (
      this.$route.name === "ProdutoVisualizar" ||
      this.$route.name === "ProdutoEditar"
    ) {
      this.formData.type = this.item.type;
      this.formData.status = this.item.status;
      this.logoPreview = this.item.brandImageUrl;
      this.produtoPreview = this.item.productImageUrl;
      this.formData.name = this.item.name;

      if (this.item.line) {
        this.formData.linesId = {
          id: this.item.line.id,
          label: this.item.line.name,
        };
      }

      this.formData.laboratoriesId = this.item.laboratoriesId;
      this.formData.composition = this.item.composition;

      if (this.item.classification) {
        this.formData.classificationsId = {
          id: this.item.classification.id,
          label: this.item.classification.name,
        };
      } else {
        this.formData.classificationsId = null;
      }

      this.formData.therapeuticClass = this.item.therapeuticClass
        ? this.item.therapeuticClass
        : "";

      this.formData.anatomiesPhysiologiesId =
        this.item.anatomiesPhysiologies.map((item) => ({
          id: item.id,
          label: item.name,
        }));

      this.formData.pathologiesId = this.item.pathologies.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      this.formData.mechanismAction = this.item.mechanismAction
        ? this.item.mechanismAction
        : "";
      this.formData.presentations = this.item.presentations
        ? this.item.presentations
        : "";
      this.formData.indication = this.item.indication
        ? this.item.indication
        : "";
      this.formData.dosage = this.item.dosage ? this.item.dosage : "";
      this.formData.patientProfile = this.item.patientProfile
        ? this.item.patientProfile
        : "";
      this.formData.benefits = this.item.benefits ? this.item.benefits : "";
      this.formData.advantages = this.item.advantages
        ? this.item.advantages
        : "";
      this.formData.strengths = this.item.strengths ? this.item.strengths : "";
      this.formData.weaknesses = this.item.weaknesses
        ? this.item.weaknesses
        : "";
      this.formData.keyMessage = this.item.keyMessage
        ? this.item.keyMessage
        : "";
      this.formData.characteristics = this.item.characteristics
        ? this.item.characteristics
        : "";

      if (this.item.directCompetitors.length > 0) {
        this.item.directCompetitors.forEach((item) => {
          this.formData.directProductsId.push(item);
        });
      }

      if (this.item.indirectCompetitors.length > 0) {
        this.item.indirectCompetitors.forEach((item) => {
          this.formData.indirectProductsId.push(item);
        });
      }

      this.formData.specialtiesId = this.item.specialties.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      this.formData.salesForcesIds = this.item.salesForces.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      this.formData.suggestedApproach = this.item.suggestedApproach
        ? this.item.suggestedApproach
        : "";
      this.formData.pdv = this.item.pdv ? this.item.pdv : "";

      if (this.item.links) {
        this.formData.links = JSON.parse(this.item.links);
      }

      this.formData.observation1 = this.item.observation_1
        ? this.item.observation_1
        : "";
      this.formData.observation2 = this.item.observation_2
        ? this.item.observation_2
        : "";
      this.formData.observation3 = this.item.observation_3
        ? this.item.observation_3
        : "";
      this.formData.observation4 = this.item.observation_4
        ? this.item.observation_4
        : "";
      this.formData.observation5 = this.item.observation_5
        ? this.item.observation_5
        : "";

      this.isEdit = true;
    } else if (
      this.$store.state.duplicateProduct.length &&
      this.$store.state.duplicateProduct[0]
    ) {
      const duplicate = { ...this.$store.state.duplicateProduct[0] };

      this.formData.status = duplicate.status;
      this.logoPreview = duplicate.brandImageUrl;
      this.produtoPreview = duplicate.productImageUrl;
      this.formData.name = duplicate.name;

      if (duplicate.line) {
        this.formData.linesId = {
          id: duplicate.line.id,
          label: duplicate.line.name,
        };
      }

      this.formData.laboratoriesId = duplicate.laboratoriesId;
      this.formData.composition = duplicate.composition;

      if (duplicate.classification) {
        this.formData.classificationsId = {
          id: duplicate.classification.id,
          label: duplicate.classification.name,
        };
      } else {
        this.formData.classificationsId = null;
      }

      this.formData.therapeuticClass = duplicate.therapeuticClass
        ? duplicate.therapeuticClass
        : "";

      this.formData.anatomiesPhysiologiesId =
        duplicate.anatomiesPhysiologies.map((item) => ({
          id: item.id,
          label: item.name,
        }));

      this.formData.pathologiesId = duplicate.pathologies.map((duplicate) => ({
        id: duplicate.id,
        label: duplicate.name,
      }));

      this.formData.mechanismAction = duplicate.mechanismAction
        ? duplicate.mechanismAction
        : "";
      this.formData.presentations = duplicate.presentations
        ? duplicate.presentations
        : "";
      this.formData.indication = duplicate.indication
        ? duplicate.indication
        : "";
      this.formData.dosage = duplicate.dosage ? duplicate.dosage : "";
      this.formData.patientProfile = duplicate.patientProfile
        ? duplicate.patientProfile
        : "";
      this.formData.benefits = duplicate.benefits ? duplicate.benefits : "";
      this.formData.advantages = duplicate.advantages
        ? duplicate.advantages
        : "";
      this.formData.strengths = duplicate.strengths ? duplicate.strengths : "";
      this.formData.weaknesses = duplicate.weaknesses
        ? duplicate.weaknesses
        : "";
      this.formData.keyMessage = duplicate.keyMessage
        ? duplicate.keyMessage
        : "";
      this.formData.characteristics = duplicate.characteristics
        ? duplicate.characteristics
        : "";

      if (duplicate.directCompetitors.length > 0) {
        duplicate.directCompetitors.forEach((duplicate) => {
          this.formData.directProductsId.push(duplicate);
        });
      }

      if (duplicate.indirectCompetitors.length > 0) {
        duplicate.indirectCompetitors.forEach((duplicate) => {
          this.formData.indirectProductsId.push(duplicate);
        });
      }

      this.formData.specialtiesId = duplicate.specialties.map((duplicate) => ({
        id: duplicate.id,
        label: duplicate.name,
      }));

      this.formData.salesForcesIds = duplicate.salesForces.map((duplicate) => ({
        id: duplicate.id,
        label: duplicate.name,
      }));

      this.formData.suggestedApproach = duplicate.suggestedApproach
        ? duplicate.suggestedApproach
        : "";
      this.formData.pdv = duplicate.pdv ? duplicate.pdv : "";

      if (duplicate.links) {
        this.formData.links = JSON.parse(duplicate.links);
      }

      this.formData.observation1 = duplicate.observation_1
        ? duplicate.observation_1
        : "";
      this.formData.observation2 = duplicate.observation_2
        ? duplicate.observation_2
        : "";
      this.formData.observation3 = duplicate.observation_3
        ? duplicate.observation_3
        : "";
      this.formData.observation4 = duplicate.observation_4
        ? duplicate.observation_4
        : "";
      this.formData.observation5 = duplicate.observation_5
        ? duplicate.observation_5
        : "";
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateLinks() {
      this.$refs.formLinks.validate();
    },
    async send() {
      const checkComposition =
        this.formData.status === "Publicado" ? this.formData.composition : true;
      if (this.formValid && checkComposition) {
        const data = await this.setPayload(this.formData);
        this.$emit("send", data);
      } else {
        this.required = true;
        this.$emit("validade", "Verifique os campos obrigatórios");
      }
    },
    preview(event) {
      if (event) {
        const data = URL.createObjectURL(event);
        this.logoPreview = data;
      }
    },
    clearPreview() {
      if (this.item.brandImageUrl) {
        this.logoPreview = this.item.brandImageUrl;
      } else {
        this.logoPreview = null;
      }
    },
    previewProduto(event) {
      if (event) {
        const data = URL.createObjectURL(event);
        this.produtoPreview = data;
      }
    },
    clearPreviewProduto() {
      if (this.item.productImageUrl) {
        this.produtoPreview = this.item.productImageUrl;
      } else {
        this.produtoPreview = null;
      }
    },
    adicionarLink() {
      if (this.formLinksValid) {
        this.formData.links.push({
          url: this.url,
          title: this.title,
        });
        (this.link = ""), (this.title = "");
        this.$refs.formLinks.reset();
      }
    },
    removerLink(index) {
      if (index > -1 && index < this.formData.links.length) {
        this.formData.links.splice(index, 1);
      }
    },
    adicionarProduto(arr, obj) {
      if (obj) {
        if (!arr.some((item) => item.id === obj.id)) {
          arr.push({
            id: obj.id,
            name: obj.attributes.name,
            laboratory: {
              name: obj.attributes.laboratoryName,
            },
          });
          obj = null;
        }
      }
    },
    removerProduto(arr, index) {
      if (index > -1 && index < arr.length) {
        arr.splice(index, 1);
      }
    },
    goTo(id) {
      const editProduct = this.$router.resolve({
        name: "ProdutoVisualizar",
        params: { id: id },
      });
      window.open(editProduct.href, "_blank");
    },
    setPayload(payload) {
      let data = new FormData();

      data.append("status", payload.status);

      data.append("name", payload.name);

        data.append("type", payload.type ?? "");

        if (payload.brandImageFile) {
          data.append("brand_image_file", payload.brandImageFile);
        } else if (!this.logoPreview) {
          data.append("brand_image", "");
        } else {
          data.append("brand_image", this.item.brandImage);
        }

        if (payload.productImageFile) {
          data.append("product_image_file", payload.productImageFile);
        } else if (!this.produtoPreview) {
          data.append("product_image", "");
        } else {
          data.append("product_image", this.item.productImage);
        }

        if (!payload.linesId) {
          data.append("lines_id", "");
        } else if (typeof payload.linesId === "string") {
          data.append("lines_id", payload.linesId);
        } else {
          data.append("lines_id", payload.linesId.id);
        }

        data.append("laboratories_id", payload.laboratoriesId ?? "");
        data.append("composition", payload.composition ?? "" );
        data.append("therapeutic_class", payload.therapeuticClass);

        if (payload.classificationsId) {
          data.append("classifications_id", payload.classificationsId.id);
        } else if (!payload.classificationsId) {
          data.append("classifications_id", "");
        } else {
          data.append("classifications_id", []);
        }

        for (let i = 0; i < payload.anatomiesPhysiologiesId.length; i++) {
          if (payload.anatomiesPhysiologiesId[i].id) {
            data.append(
              "anatomies_physiologies_id[]",
              payload.anatomiesPhysiologiesId[i].id
            );
          } else {
            data.append(
              "anatomies_physiologies_id[]",
              payload.anatomiesPhysiologiesId[i]
            );
          }
        }

      if (payload.pathologiesId.length > 0) {
        for (let i = 0; i < payload.pathologiesId.length; i++) {
          if (payload.pathologiesId[i].id) {
            data.append("pathologies_id[]", payload.pathologiesId[i].id);
          } else {
            data.append("pathologies_id[]", payload.pathologiesId[i]);
          }
        }
      } else {
        data.append("pathologies_id", []);
      }

      data.append("mechanism_action", payload.mechanismAction);

      data.append("presentations", payload.presentations);
      data.append("indication", payload.indication);
      data.append("dosage", payload.dosage);

      data.append("patient_profile", payload.patientProfile);
      data.append("benefits", payload.benefits);
      data.append("advantages", payload.advantages);

      data.append("characteristics", payload.characteristics);
      data.append("key_message", payload.keyMessage);
      data.append("strengths", payload.strengths);
      data.append("weaknesses", payload.weaknesses);

      if (payload.directProductsId.length > 0) {
        for (let i = 0; i < payload.directProductsId.length; i++) {
          data.append("direct_products_id[]", payload.directProductsId[i].id);
        }
      } else {
        data.append("direct_products_id", []);
      }

      if (payload.indirectProductsId.length > 0) {
        for (let i = 0; i < payload.indirectProductsId.length; i++) {
          data.append(
            "indirect_products_id[]",
            payload.indirectProductsId[i].id
          );
        }
      } else {
        data.append("indirect_products_id", []);
      }

      if (!payload.specialtiesId) {
        data.append("specialties_id", "");
      } else {
        for (let i = 0; i < payload.specialtiesId.length; i++) {
          if (payload.specialtiesId[i].id) {
            data.append("specialties_id[]", payload.specialtiesId[i].id);
          } else {
            data.append("specialties_id[]", payload.specialtiesId[i]);
          }
        }
      }

      if (payload.salesForcesIds.length > 0) {
        for (let i = 0; i < payload.salesForcesIds.length; i++) {
          if (payload.salesForcesIds[i].id) {
            data.append("sales_forces_id[]", payload.salesForcesIds[i].id);
          } else {
            data.append("sales_forces_id[]", payload.salesForcesIds[i]);
          }
        }
      } else {
        data.append("sales_forces_id", []);
      }

      data.append("suggested_approach", payload.suggestedApproach);
      data.append("pdv", payload.pdv);

      if (payload.links.length > 0) {
        for (let i = 0; i < payload.links.length; i++) {
          data.append(`links[${i}][url]`, payload.links[i].url);
          data.append(`links[${i}][title]`, payload.links[i].title);
        }
      } else {
        data.append(`links`, []);
      }

      data.append("observation_1", payload.observation1);
      data.append("observation_2", payload.observation2);
      data.append("observation_3", payload.observation3);
      data.append("observation_4", payload.observation4);
      data.append("observation_5", payload.observation5);

      return data;
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
    formLinksValid() {
      return this.$refs.formLinks.validate();
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep.theme--light.v-input--is-disabled input,
::v-deep.theme--light.v-select.v-input--is-disabled
  .v-select__selections
  .v-chip {
  color: rgba(0, 0, 0, 1);
  opacity: 1;
}
</style>
